import { Layout, Footer, Link } from 'components';
import { HeaderSection, LearnMoreSection, BenefitsSection } from 'page-components/program';
import { color } from 'style';
import headerImage from 'assets/images/profession-has-no-gender-header.svg';
import metaImage from 'assets/images/phng-meta.png';
import logoImage from 'assets/images/PHNG-logo.svg';

const benefits = [
  <span key={1}>
    <strong>παροτρύνει</strong> να διευρύνουν το φάσμα των επιλογών τους σχετικά με τις σπουδές και τα επαγγέλματα που
    μπορούν να ακολουθήσουν
  </span>,
  <span key={2}>
    <strong>υποστηρίξει</strong> να φτάσουν το μέγιστο των δυνατοτήτων τους
  </span>,
  <span key={3}>
    <strong>βοηθήσει</strong> να ανατρέψουν τις στερεοτυπικές αντιλήψεις που πιθανόν να έχουν διαμορφώσει και που
    αποτελούν τροχοπέδη στην προσωπική τους εξέλιξη
  </span>,
  <span key={4}>
    <strong>εμπνεύσει</strong>, ακόμα και τον μαθητή και τη μαθήτρια στο πιο απομακρυσμένο χωριό, ώστε να επιδιώξουν τα
    όνειρά τους χωρίς περιορισμούς
  </span>,
  <span key={5}>
    <strong>ενδυναμώσει</strong> ώστε να ανακαλύψουν τα ενδιαφέροντά τους με κριτήριο τα ταλέντα και τις κλίσεις τους,
    και όχι στερεοτυπικές αντιλήψεις
  </span>,
];

const Page = () => (
  <Layout
    logo={logoImage}
    meta={{
      title: 'Profession has no Gender',
      description: 'Για ένα αύριο χωρίς έμφυλα στερεότυπα στην εργασία!',
      image: metaImage,
    }}
  >
    <HeaderSection
      title="Profession has no Gender"
      subtitle={
        <span>
          Η σύνδεση επαγγελμάτων με κάποιο συγκεκριμένο φύλο συχνά διαμορφώνεται ήδη από τα σχολικά χρόνια, επιδρώντας,
          έτσι, στη διαμόρφωση των επαγγελματικών επιλογών.
          <br />
          Σκοπός του προγράμματος είναι να προσφέρει παραδείγματα και επιχειρήματα σε μαθητές και μαθήτριες Δημοτικού,
          Γυμνασίου και Λυκείου, και να ενισχύσει έτσι το μήνυμα πως αν το βασικό κριτήριο επιλογής επαγγέλματος είναι η
          κλίση, τα ταλέντα και τα ενδιαφέροντά τους, μπορούν να διαπρέψουν σε οποιονδήποτε κλάδο ανεξάρτητα από το φύλο
          τους.
          <br />
          Γιατί … το επάγγελμα δεν έχει φύλο!
        </span>
      }
      image={headerImage}
      imageWidth={369}
      imageAlt="Ισότητα των φύλων στον επαγγελματικό χώρο"
    />
    <LearnMoreSection
      title="Σχετικά με το πρόγραμμα"
      text={
        <span>
          Η Τράπεζα Πειραιώς, έχοντας τοποθετήσει ψηλά στην ατζέντα της την Ισότητα των Φύλων, συμπράττει με τον
          οργανισμό The Tipping Point για την υλοποίηση του προγράμματος “Profession has no Gender”.
          <br />
          <br />
          Έχοντας ως στόχο την επιλογή της επαγγελματικής πορείας χωρίς περιορισμούς από νεαρή ηλικία, ομάδες μαθητών
          και μαθητριών δημοσίων σχολείων έρχονται σε επαφή με μέντορες που δραστηριοποιούνται σε επαγγέλματα που είναι
          στερεοτυπικά συνυφασμένα με το αντίθετο φύλο.
          <br />
          <br />
          Γυναίκες και άνδρες από την Ελλάδα και το εξωτερικό, αναλαμβάνουν τον ρόλο των μεντόρων, προκειμένου να
          μοιραστούν την πορεία, τις εμπειρίες, τις προκλήσεις και τα επιτεύγματά τους, με στόχο να εμπνεύσουν τη νέα
          γενιά να ακολουθήσει την επαγγελματική πορεία που επιθυμεί, ανεξαρτήτως φύλου. Οι μέντορες προέρχονται από
          πληθώρα επαγγελματικών κλάδων, όπως γυναίκες μηχανικοί, επιστήμονες και CEOs, άνδρες νοσηλευτές, κοινωνικοί
          λειτουργοί, νηπιαγωγοί, κ.ά. Σε ένα δομημένο ψηφιακό περιβάλλον, οι ομάδες μαθητών και μαθητριών, σε
          συνεργασία με τους εκπαιδευτικούς τους, “συναντούν” και συζητούν διαδικτυακά με τους/τις μέντορες, μέσα από
          live ομαδικές συνεδρίες και ασύγχρονων video-απαντήσεων στα ερωτήματα που θέτουν. Με αυτόν τον τρόπο, μπορούν
          να εκφράσουν τους προβληματισμούς τους και να λύσουν τις απορίες τους μέσα από διαδραστικές και
          εποικοδομητικές συζητήσεις.
          <br />
          <br />Η συμμετοχή στο πρόγραμμα είναι δωρεάν και αφορά σε δημόσια σχολεία πρωτοβάθμιας και δευτεροβάθμιας
          εκπαίδευσης, τα οποία ανήκουν στο δίκτυο του The Tipping Point (800+ σχολεία ανά τη χώρα).
        </span>
      }
    />
    <BenefitsSection
      backgroundColor={color.hmPurple1}
      heading="Στόχοι του προγράμματος"
      subHeading="Για το 2024, στόχος του προγράμματος είναι να διατεθεί σε 3.000 επιπλέον μαθητές και μαθήτριες, εκ των οποίων 600 φοιτούν σε δημοτικά σχολεία, προκειμένου να:"
      bullets={benefits}
      footer={
        <>
          Για περισσότερες πληροφορίες επικοινωνήστε στο email: <br />
          <Link href="mailto:professionhasnogender@thetippingpoint.org.gr">
            professionhasnogender@thetippingpoint.org.gr
          </Link>
        </>
      }
    />
    <Footer logos={['piraeus', 'ttp']} />
  </Layout>
);

export default Page;
